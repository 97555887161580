/* eslint-disable react/jsx-key */
import React , {Component} from 'react';
import { ResponsiveTestimonialTab, 
  MenuListV2Main, CustomListItemV2Main, WrapperContainer, RatingsContainers,
  Divider, PaddingContainer, /*RatingWrapper,*/ TabsContainerTestimonial} from '../WellnessPageV2/styles';
import { ImageUrl, imgPath } from '../../utils/constants';
import {ButtonLearnMoree,TabData, Button2, Section, IntroTextContainer, PosterImage,ResourcesWrapper,RowWrapper,MainResources,Resources,RowResponsiveWrapper, ButtonWatchVideo, VideoButtonV1, CoreFeatureSectionV1, TextImagesContainer, /*TabImagesContainer,*/ CoreWrapper, TopCard, RatingWrapperr, RatingWrapper, Wrapper, WellBeing, DemoText, ButtonGetStarted} from './style';
import PropTypes from "prop-types";
import data from './wellnessResources.json'
import {MenuLinks} from '../NavigationBarV2/styles';
import {connect} from 'react-redux';
import {subscribeNewsLetter} from '../../redux/actions/authActions';
import { CoreListt, CustomListt, MenuListV2Main3, TabsContainerV3 } from './style';
import  testimonialData from './TestimonialData.json'
const DemoPopup = React.lazy(() => import('../WhyAmigoCommon/DemoPopup'));
import coreData from './CoreFeature.json';
import LazyImage from '../common/LazyImage/LazyImage';
class CommunityRecognitionPage extends Component{

  constructor(){
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active:'',
      selectedImage:'',
      selectedText:'WHY USERS LOVE US',
      selectedTab: 0,
      selectedSubTab: 0,
      // selectedProTab: 0,
      selectedCoreTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [data[0].data[0].image, data[1].data[0].image, data[2].data[0].image,data[3].data[0].image],
      email: '',
      tabArray: ["50-200 employees", "201-500 employees", "501-1000 employees"],
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main],
      coreButtonText: [coreData[0].buttonText, coreData[1].buttonText, coreData[2].buttonText, coreData[3].buttonText, coreData[4].buttonText, coreData[5].buttonText],
      coreInfo2: [coreData[0].data2, coreData[1].data2, coreData[2].data2, coreData[3].data2, coreData[4].data2, coreData[5].data2],
      coreInfo: [coreData[0].data, coreData[1].data, coreData[2].data, coreData[3].data, coreData[4].data, coreData[5].data],
      coreHead: [coreData[0].head, coreData[1].head, coreData[2].head, coreData[3].head, coreData[4].head, coreData[5].head],
      coreName: [coreData[0].name, coreData[1].name, coreData[2].name, coreData[3].name, coreData[4].name, coreData[5].name],
      coreImage: [coreData[0].img, coreData[1].img, coreData[2].img, coreData[3].img, coreData[4].img, coreData[5].img],
      currentDiv: [true, false, false, false, false, false],
      showVideo: false,
    }
  }

  componentDidMount(){
    this.setState({active:'Insurance'})
  }

  changeModal = () => {
    this.setState({ showVideo: !this.state.showVideo });
  };

  redirection = () => {
    const { history } = this.props;
    history.push('/demo');
  }

  top =() => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
          Celebrate Teamwork,<br/>
          Empower Employees!
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/pinkline.png"}/>
        <p>
        A recognition platform built to empower employees,{this.state.mobileViewStatus ? '' : <br/>} 
        fuel productivity and boost retention.{this.state.mobileViewStatus ? '' : <br/>}
        </p>
        <div className="wrap">
          <a href='https://woliba.navattic.com/dq4p0y17' target='blank' style={{textDecoration:"none"}}><ButtonWatchVideo style={{display:'flex',alignItems:"center",justifyContent:"center"}}><LazyImage noMargin={1}  className='pink-triangle' src={ImageUrl + '/images/pink-triangle.png'}/><div style={{marginLeft:'8%'}}>Take a Tour</div></ButtonWatchVideo></a>  
          <ButtonLearnMoree onClick={()=> this.props.history.push('/contact')} noMargin={1}>REQUEST A DEMO</ButtonLearnMoree>
          <VideoButtonV1 onClick={() => this.changeModal()}>
            <i className="fa fa-play" />
            QUICK INTRO VIDEO
          </VideoButtonV1>
        </div>
      </IntroTextContainer>
      <PosterImage>
        <picture>
          <source srcSet={ImageUrl + "/images/RecognitionV2/Banner_web_image.webp"} type="image/png"/>
          <LazyImage src={ImageUrl + "/images/RecognitionV2/Banner_web_image.webp"} title="Poster Image"/>
        </picture>
      </PosterImage>
      <LazyImage src={ImageUrl + "/images/recognition_mobile_banner.png"} title="Poster Image"/>
    </Section>
  )

  middleImages = () => (
    <TextImagesContainer>
      <div className='text-wrapper'>
        Highly rated by<br/>
        Global Software<br/> 
        Review Platforms
      </div>
      <div className='image-wrapper'>
        <LazyImage className='first-img' src={ImageUrl + "/images/G2.svg"}/>
        <LazyImage src={ImageUrl + "/images/Get_app.svg"}/>
        <LazyImage src={ImageUrl + "/images/Capterra.svg"}/>
        <LazyImage src={ImageUrl + "/images/SA_Badge_FrontRunners_2022_FullColor.svg"}/>
      </div>
    </TextImagesContainer>
  )
  
  ratingsUI = () => (
    <RatingWrapperr>
      <RatingsContainers>
        <LazyImage div={"image"} src={ImageUrl + this.state.ratingImage[this.state.selectedTab]} alt={"rating"} />
        <LazyImage div={"ratingStars"} src={ImageUrl + "/images/HomePageV2/ratingStars.png"} alt={"rating"} />
        <div className={"ratingDescription"}>{this.state.ratingData[this.state.selectedTab]}</div>
        <div className={"ratingBy"}>{this.state.ratingName[this.state.selectedTab]} <span>{this.state.ratingMain[this.state.selectedTab]}</span></div>
      </RatingsContainers>
    </RatingWrapperr>
  );

  decreaseIndex = () => {
    const {selectedTab} = this.state;
    let temp = selectedTab-1;
    this.setState({selectedTab: temp})
  }

  increaseIndex = () => {
    const {selectedTab} = this.state;
    let temp = selectedTab+1;
    this.setState({selectedTab: temp})
  }

  handleTabs = (index) => {
    this.setState({selectedTab: index});
  }

  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "50-200 employees",
        image: "/path/",
        text: "Healthcare data"
      },
      {
        tabName: "201-500 employees",
        image: "/path/",
        text: "civil data"
      },
      {
        tabName: "501-1,000 employees",
        image: "/path/",
        text: "Insurance data"
      }
    ]

    return(
      <TabsContainerTestimonial>
        <MenuListV2Main>
          {TabArray.map((data, index) => (
            <CustomListItemV2Main showTab={true} active={this.state.selectedTab === index} key={index} 
              onClick={() => this.handleTabs(index)}>
              <MenuLinks style={{textTransform: "lowercase"}}>{data.tabName}</MenuLinks>
            </CustomListItemV2Main>
          ))}
        </MenuListV2Main>
      </TabsContainerTestimonial>)
  };

  renderTabData=()=>(
    <TabData>
      <div className="responsiveWhyButton">
        <Button2 display={'block'} onClick={()=> this.props.history.push('/contact')}>BOOK A DEMO</Button2>
      </div>
      <div>
        <img src="/public/images/CommunityV2/Community_Image.png"/>
      </div>
      <div>
        <h1>Connect Peers That Share{this.state.mobileViewStatus ? '' : <br/>} Similar Interests And Goals</h1>
        <img src="/public/images/HomePageV2/blueDivider.png"/>
        <p>Uniting cross-functional teams allows for more<br/> 
          innovation employee development and growth.</p>
        <Button2 display={'block'} onClick={()=> this.props.history.push('/contact')}>REQUEST A DEMO</Button2>
      </div>
    </TabData>
  )

  handleResources = (resIndex, rowIndex) => {
    const {wellnessResources, image} = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status===1 && 0;
    })
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image; 
    this.setState({wellnessResources: array, image: images});
  }

  multipleFeatures = () => (
    <ResourcesWrapper>{
      this.state.wellnessResources.length > 0 && this.state.wellnessResources.map((row,index)=>
        (<MainResources key={index} color={row.color}><h3>{row.heading}</h3>
          <LazyImage div={"line"} src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
          <Resources flexDirection={row.swapRow} col={row.color} align={index%2 === 0? 'flex-end': 'flex-start'}>
            <div>
              <div>
                <LazyImage src={ImageUrl +"/images/" + `${row.icon}`} alt={row.title}/>
                <h4 className={"title"}>{row.title}</h4>
              </div>
              <div className="normal">
                {
                  row.data.map((info, rowindex) => (
                    <RowWrapper key={rowindex} selected={info.status} bgColor={row.bgColor} 
                      onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div>{info.header}</div>
                        <div className={'downArrow'}><img src={'/public/images/WellnessPage/Vector (1).svg'} alt={"arrow"}/></div>
                      </div>
                      {(info.status === 1) && <div className={"expand"}>
                        {info.description}
                      </div>}
                    </RowWrapper>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper key={rowindex} selected={info.status} bgColor={row.bgColor} onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <div className={'downArrow'}><img src={'/public/images/WellnessPage/Vector (1).svg'} alt={"arrow"}/></div>
                        </div>
                        {(info.status === 1) && <div className={"expand"}>
                          {info.description}
                        </div>}
                      </RowResponsiveWrapper>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
            <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

          </Resources>
        </MainResources>))
    }</ResourcesWrapper>);
  coreFeatureSection = () => (
    <CoreFeatureSectionV1>
      <div>
        <h2>Ignite employee engagement</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/Rectanglewellness.png"}/>
      </div>
    </CoreFeatureSectionV1>
  );

  watchDemoVideo = () => (
    <TopCard>
      <div className='topcard-demo-wrapper'>
        <div className='topcard-demo'>
          <div className='topcard-pic'>
            <img className='enter-img' src='/public/images/request_a_demo.svg'></img>
          </div>
          <div className='topcard-content'>
            <div className='upper-content'>
              Don’t ready to chat Yet?
            </div>
            <div className='lower-content'>
              <p>See how Woliba can help you manage all of your</p>

              <p>employee wellness and Welbeing in one place And</p>

              <p>easily keep all your employee health in control</p>
            </div>
            <div className='lower-mobb-content'>
              <p>See how Woliba can help you manage all of your

                employee wellness and Welbeing in one place And

                easily keep all your employee health in control</p>
            </div>
          </div>
          <button className='demo-btn' onClick={() => this.redirection()}>
            <div className='watch-text' >Watch Recorded Demo</div>
          </button>
        </div>
      </div>
    </TopCard>
  );

  ratingshead = () => (
    <RatingWrapper>
      <Wrapper>
        <h2>Loved by Clients</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </Wrapper>
    </RatingWrapper>
  );

  wellBeingSection = () => (
    <WellBeing background={"rgb(253, 113, 117)"}>
      <div style={{background:'rgb(253, 113, 117)'}}>
        <div>
          <p style={{marginBottom:0}} >Want to see the full <br />
            Woliba experience?</p>
          <DemoText>
            Request a demo to meet with one of our<br />
            wellness specialists.
          </DemoText>
          <ButtonGetStarted display={'block'} onClick={() => this.props.history.push('/contact')}>Schedule A Live Demo</ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/Schedule_a_demo.webp"} />
    </WellBeing>
  )

  onEmailChange = (e) => {
    this.setState({email: e.target.value})
  }

  divider=()=>(
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"}/>
    </Divider>
  )
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if(email.trim() !== '') {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  renderResponsiveTab = () => (
    <ResponsiveTestimonialTab>
      {this.state.selectedTab > 0 ? 
        this.state.selectedTab > 0 && <LazyImage div="leftArrow" onClick={() => this.decreaseIndex()} src={ImageUrl + "/images/HomePageV2/leftArrow.png"} />
        : <div className="leftArrow"></div>}
      <div>
        <p>
          {
            this.state.tabArray[this.state.selectedTab]
          }
        </p>
      </div>
      {this.state.selectedTab < 2 && 
          this.state.selectedTab < 2 && <LazyImage div="rightArrow" onClick={() => this.increaseIndex()} src={ImageUrl + "/images/HomePageV2/rightArrow.png"} /> }
    </ResponsiveTestimonialTab>
  );   
  
  // tabDataAndImages = () => (
  //   <TabImagesContainer>
  //     <div className='top-text'>
  //       Keep your finger on the pulse<br/>
  //       of performance, culture, and collaboration
  //     </div>
  //     <div className='blue-box-data'>
  //       <p></p>
  //     </div>
  //   </TabImagesContainer>
  // );

  handleSubTabs = (index) => {
    this.setState({selectedSubTab: index});
  }
    
  handleSubResponsiveTabs = (resIndex) => {
    const {currentDiv} = this.state;
    let array = currentDiv;
    array.forEach((data,index)=>{
      array[index] = false;
    })
    array[resIndex] = true;
    this.setState({selectedCoreTab: resIndex});
    this.setState({currentDiv: array});
    if(resIndex===this.state.selectedCoreTab){
      this.setState({showtab: !this.state.showtab});
    }
  }

  coreFeature = () => {
    let SubTabArray = [
      {
        tabName: "Peer Recognition",
        image: "/path/",
      },
      {
        tabName: "Company Analytics",
        image: "/path/",
      },
      {
        tabName: "Company Values",
        image: "/path/",
      },
      {
        tabName: "Best Performer",
        image: "/path/",
      }
    ]
    return(
      <CoreWrapper>
        <div>
          <h2>Improve workplace Wellbeing with our<br/> 
            easy-to-Read Interactive Dashboard</h2>
          {/* <img src = "/images/HomePageV2/line.svg"></img> */}
        </div>
        <div>
          <TabsContainerV3>
            <MenuListV2Main3>
              {SubTabArray.map((data, index) => (
                <CustomListt showTab={true} active={this.state.selectedSubTab === index} key={index} 
                  onClick={() => this.handleSubTabs(index)}>
                  <MenuLinks><h3 style={{fontSize:'20px'}}>{data.tabName}</h3></MenuLinks>
                </CustomListt>
              ))}
            </MenuListV2Main3>
          </TabsContainerV3>
        </div>
        <div>
          <div>
            <div  className="box-top-line" >        
              <p>{this.state.coreInfo[this.state.selectedSubTab]}</p>
              <br/>
              {/* <p>{this.state.coreInfo2[this.state.selectedSubTab]}</p> */}
            </div>
            {/* <BookDemo onClick={() => this.wellnessPageRedirection(this.state.selectedSubTab)} 
              noMargin={1}>{this.state.coreButtonText[this.state.selectedSubTab]}
              <i className="fas fa-chevron-right" style={{"marginLeft": "10px"}}></i>
            </BookDemo> */}
          </div>
          <LazyImage src={ImageUrl + this.state.coreImage[this.state.selectedSubTab]}/>
        </div>
        <div className = "responsive-core">
          {SubTabArray.map((data, index) => (
            <CoreListt active={this.state.selectedCoreTab === index && this.state.showtab? 1 : 0} key={index} 
              onClick={() => this.handleSubResponsiveTabs(index)}>
              <div style={{alignItems:"center"}}>
                <h3>{data.tabName}</h3>
                <LazyImage src={ImageUrl + "/images/HomePageV2/down.png"}/> 
              </div>
              { this.state.currentDiv[index] && this.state.showtab &&
                <div className="selectedCoreContent">
                  <div>
                    <h2>{this.state.coreHead[this.state.selectedCoreTab]}</h2>
                    <img src = "/public/images/HomePageV2/Rectangle.png" />
                    <div>        
                      <p>{this.state.coreInfo[this.state.selectedCoreTab]}</p>
                      <br/>
                      {/* <p>{this.state.coreInfo2[this.state.selectedCoreTab]}</p> */}
                    </div>
                    {/* <ButtonBookDemo onClick={()=> this.wellnessPageRedirection(this.state.selectedCoreTab)} 
                      noMargin={1}>{this.state.coreButtonText[this.state.selectedCoreTab]}</ButtonBookDemo> */}
                  </div>
                  <LazyImage src={ImageUrl + this.state.coreImage[this.state.selectedSubTab]}/>
                </div>
              }
              <LazyImage div={"coreDivider"} src={ImageUrl + "/images/HomePageV2/CoreDivider.png"}/>
            </CoreListt>
          ))}
        </div>
      </CoreWrapper>)
  };

  render() {
    const { showVideo } = this.state;
    return(
      <WrapperContainer>
        {this.top()}
        <PaddingContainer>{this.coreFeatureSection()}</PaddingContainer>
        <PaddingContainer>{this.multipleFeatures()}</PaddingContainer>
        {/* {this.tabDataAndImages()} */}
        {this.middleImages()}
        {this.coreFeature()}
        {/* {this.watchDemoVideo()} */}
        {this.ratingshead()}
        {this.ratingsUI()}
        {this.renderResponsiveTab()}
        <PaddingContainer>{this.renderMenuList()}</PaddingContainer>
        {/* {this.renderTabData()} */}
        <PaddingContainer>{this.wellBeingSection()}</PaddingContainer>
        {showVideo && <DemoPopup showModal={showVideo} onClose={this.changeModal} videoUrl={"https://vimeo.com/818393562"} VideoTitle={"Woliba Employee Recognition"}/>}
      </WrapperContainer>
    );
  }
}
CommunityRecognitionPage.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});
export default connect(null, mapDispatchToProps)(CommunityRecognitionPage);
